import React, { ReactElement } from "react";
import ReactDom from "react-dom";

interface ModalPortalPropsType {
  children: ReactElement;
}

const ModalPortal: React.FC<ModalPortalPropsType> = ({ children }) => {
  const el: HTMLElement | null = document.getElementById("aside-root");

  if (!el) return null; // 'el'이 null일 경우 null 반환

  return ReactDom.createPortal(children, el);
};

export default ModalPortal;
