import React from "react";
import { colors } from "theme/colors";
import { Box } from "theme/globalStyle";

interface DividerType {
  isVertical?: boolean;
  variant: "normal" | "thick"; //thick일경우 height 조절 가능
  width?: string | number;
  height?: string | number;
  bgColor?: string;
}

export const Divider = React.memo(
  ({ isVertical, variant, width, height, bgColor }: DividerType) => {
    return (
      <Box
        width={isVertical ? 0.1 : width}
        height={isVertical || variant === "thick" ? height : 0.1}
        $bgcolor={bgColor || colors.DIVIDER_COLOR}
      />
    );
  }
);
