import React, { CSSProperties, ReactNode } from "react";
import { colors } from "theme/colors";
import { FontStyleElType } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

import styled from "styled-components";
import { marginPaddingMixin } from "theme/globalStyle";
import { CustomTextType } from "theme/styles.type";

export const Text = styled.span<CustomTextType>`
  font-size: ${({ $fs }) => $fs && `${$fs / 10}rem`};
  color: ${({ $fc }) => $fc || colors.BLACK};
  font-family: ${({ $fw }) =>
    $fw ? `${$fw},'Apple SD Gothic Neo'` : `'Regular','Apple SD Gothic Neo'`};
  text-align: ${({ text_align }) => text_align || "left"};
  word-break: break-word;
  ${marginPaddingMixin}
  ${({ background }) => background && `background : ${background};`}
    ${({ textclip }) => textclip && `-webkit-background-clip: text;`}
  ${({ fontStyle }) => fontStyle && `${fontStyle}`};
  ${({ $lineHeight }) => $lineHeight && `line-height : ${$lineHeight}px;`}

  transition : color 0.3s ease-in-out;
`;

interface TypograpyType {
  fontSize?: number;
  $lineHeight?: number;
  fontWeight?:
    | "Thin"
    | "Light"
    | "Regular"
    | "Medium"
    | "SemiBold"
    | "Bold"
    | "ExtraBold";
  style?: CSSProperties;
  color?: string;
  children: ReactNode;
  type?: "default" | "pri" | "assis";
  leftIcon?: string;
  rightIcon?: string;
  fontStyle?: string;

  className?: string;
}

const Typograpy = React.memo(
  ({
    fontSize,
    $lineHeight,
    fontWeight,
    style,
    color,
    children,
    type = "default",
    leftIcon,
    rightIcon,
    fontStyle,
    className,
  }: TypograpyType) => {
    if (leftIcon || rightIcon) {
      return (
        <FlexBox
          $flex_direction="row"
          $jc="center"
          $ai="center"
          style={{ gap: "4px" }}
        >
          {leftIcon && <img src={leftIcon} width={20} height={20} />}
          <Text
            $fs={fontSize}
            $fc={color}
            $fw={fontWeight}
            $lineHeight={$lineHeight}
            fontStyle={fontStyle}
            className={className}
            style={{ ...style }}
          >
            {children}
          </Text>
          {rightIcon && <img src={rightIcon} width={20} height={20} />}
        </FlexBox>
      );
    } else {
      return (
        <Text
          style={{ ...style }}
          $fs={fontSize}
          $fc={color}
          $lineHeight={$lineHeight}
          $fw={fontWeight}
          fontStyle={fontStyle}
          className={className}
        >
          {children}
        </Text>
      );
    }
  }
);

export default Typograpy;
