import { Divider } from "components/Divider";
import { Input, TextArea } from "components/form/input";
import Typograpy from "components/Typograpy";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const emailPublicKey = process.env.REACT_APP_EMAIL_PUBLICKEY;
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const [contactInfo, setContactInfo] = React.useState({
    type: "",
    title: "",
    content: "",
    phone: "",
    returnEmail: "",
  });

  const emailForm = React.useRef<any>();

  const [btnDisable, setBtnDisable] = React.useState(true);

  //문의하기 이메일 전송
  function sendEmail() {
    if (emailForm.current) {
      emailjs
        .sendForm(
          "service_luajtmo",
          "template_hh20sul",
          emailForm.current,
          emailPublicKey
        )
        .then(
          (result) => {
            alert("문의가 완료되었습니다.");
            setContactInfo({
              type: "",
              title: "",
              content: "",
              phone: "",
              returnEmail: "",
            });
          },
          (error) => {
            alert("이메일 전송에 실패하였습니다.");
            console.error(error);
          }
        );
    }
  }

  React.useEffect(() => {
    if (
      contactInfo.title !== "" &&
      contactInfo.content !== "" &&
      contactInfo.phone !== "" &&
      contactInfo.returnEmail !== ""
    ) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [JSON.stringify(contactInfo)]);

  return (
    <FlexBox
      $flex={1}
      // $pt={isMobile ? 0 : 8.6}
      $py={isMobile ? 2 : 0}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 10}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
    >
      <FlexBox $mt={4} $isFullWidth>
        <Typograpy fontSize={isMobile ? 28 : 40} fontWeight="ExtraBold">
          CONTACT
        </Typograpy>

        <FlexBox $mt={2}>
          <Typograpy
            fontSize={isMobile ? 14 : 18}
            fontWeight="Medium"
            color={colors.MOBILE_DATE_FONT}
          >
            블루라이언스는 파트너사와의 모든 제휴나 거래에서 합리적인 조건을
            보장하며 ~
          </Typograpy>
          <Typograpy
            fontSize={isMobile ? 14 : 18}
            fontWeight="Medium"
            color={colors.MOBILE_DATE_FONT}
          >
            아래 양식에 맞춰 작성해주시면 답변을 드리겠습니다
          </Typograpy>
        </FlexBox>

        <FlexBox
          $isFullWidth
          $mt={3}
          $bgcolor={colors.GRAY_BG}
          $px={3}
          $pt={2}
          $pb={6}
          $br={isMobile ? 16 : 30}
        >
          <FlexBox
            $flex_direction={isMobile ? "column" : "row"}
            $ai={isMobile ? "flex-start" : "center"}
          >
            <Typograpy fontSize={25} fontWeight="SemiBold">
              문의사항
            </Typograpy>
            <FlexBox
              $mt={isMobile ? 1 : 0}
              $flex_direction="row"
              $ai={"center"}
            >
              <Box
                $ml={isMobile ? 0 : 2}
                $mr={0.4}
                $pa={0.3}
                $bgcolor={colors.MAIN}
                $br={100}
              />
              <Typograpy
                color={colors.SUB_FONT}
                fontSize={14}
                fontWeight="Medium"
              >
                표시는 필수정보 입력란입니다.
              </Typograpy>
            </FlexBox>
          </FlexBox>
          <Box $isFullWidth $my={2.4}>
            <Divider
              bgColor={colors.DEFAULT_BORDER_COLOR}
              variant="normal"
              width={"100%"}
            />
          </Box>
          <form style={{ width: "100%" }} ref={emailForm}>
            <FlexBox $gap={2} $isFullWidth>
              <FlexBox $isFullWidth>
                <Typograpy fontSize={18} fontWeight="Medium">
                  문의유형
                </Typograpy>
                <Box $isFullWidth $mt={1.2}>
                  <Input
                    value={contactInfo.type}
                    onChange={(e) =>
                      setContactInfo({
                        ...contactInfo,
                        type: e.target.value,
                      })
                    }
                    name={"contact_type"}
                  />
                </Box>
              </FlexBox>
              <FlexBox $isFullWidth>
                <FlexBox $flex_direction="row" $ai="center">
                  <Typograpy fontSize={18} fontWeight="Medium">
                    연락처
                  </Typograpy>
                  <Box $pa={0.3} $br={100} $bgcolor={colors.MAIN} $ml={0.8} />
                </FlexBox>
                <Box $isFullWidth $mt={1.2}>
                  <Input
                    value={contactInfo.phone}
                    onChange={(e) =>
                      setContactInfo({
                        ...contactInfo,
                        phone: e.target.value,
                      })
                    }
                    name={"user_phone"}
                  />
                </Box>
              </FlexBox>
              <FlexBox $isFullWidth>
                <FlexBox $flex_direction="row" $ai="center">
                  <Typograpy fontSize={18} fontWeight="Medium">
                    이메일
                  </Typograpy>
                  <Box $pa={0.3} $br={100} $bgcolor={colors.MAIN} $ml={0.8} />
                </FlexBox>
                <Box $isFullWidth $mt={1.2}>
                  <Input
                    value={contactInfo.returnEmail}
                    onChange={(e) =>
                      setContactInfo({
                        ...contactInfo,
                        returnEmail: e.target.value,
                      })
                    }
                    name={"user_email"}
                  />
                </Box>
              </FlexBox>
              <FlexBox $isFullWidth>
                <FlexBox $flex_direction="row" $ai="center">
                  <Typograpy fontSize={18} fontWeight="Medium">
                    제목
                  </Typograpy>
                  <Box $pa={0.3} $br={100} $bgcolor={colors.MAIN} $ml={0.8} />
                </FlexBox>
                <Box $isFullWidth $mt={1.2}>
                  <Input
                    value={contactInfo.title}
                    onChange={(e) =>
                      setContactInfo({
                        ...contactInfo,
                        title: e.target.value,
                      })
                    }
                    name={"contact_title"}
                  />
                </Box>
              </FlexBox>
              <FlexBox $isFullWidth>
                <FlexBox $flex_direction="row" $ai="center">
                  <Typograpy fontSize={18} fontWeight="Medium">
                    내용
                  </Typograpy>
                  <Box $pa={0.3} $br={100} $bgcolor={colors.MAIN} $ml={0.8} />
                </FlexBox>
                <Box $isFullWidth $mt={1.2}>
                  <TextArea
                    value={contactInfo.content}
                    onChange={(e) =>
                      setContactInfo({
                        ...contactInfo,
                        content: e.target.value,
                      })
                    }
                    name={"contact_contents"}
                  />
                </Box>
              </FlexBox>
              <FlexBox
                $isFullWidth
                $flex_direction="row"
                $jc={"space-between"}
                $ai={"center"}
              >
                <Typograpy
                  fontSize={14}
                  fontWeight="Medium"
                  color={colors.SUB_FONT}
                >
                  최대 2,000자까지 입력가능합니다.
                </Typograpy>

                <Typograpy
                  fontSize={14}
                  color={colors.SUB_FONT}
                  fontWeight="Medium"
                >
                  {contactInfo.content.length} / 2,000
                </Typograpy>
              </FlexBox>
            </FlexBox>
          </form>
        </FlexBox>
        <FlexBox $isFullWidth $ai={"center"} $mt={3}>
          <Box
            $bgcolor={btnDisable ? colors.BTN_DISABLE : colors.MAIN}
            $br={8}
            $px={5}
            $py={isMobile ? 1.5 : 2}
            style={{
              cursor: btnDisable ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              if (!btnDisable) {
                sendEmail();
              }
            }}
          >
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.WHITE}
            >
              접수하기
            </Typograpy>
          </Box>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
