import Typograpy from "components/Typograpy";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const ImgArea = styled(motion.div)``;

export const Intro = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 10}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
    >
      <FlexBox $flex={0} $gap={3} $ai={isMobile ? "center" : "flex-start"}>
        <Typograpy
          fontSize={isMobile ? 28 : 40}
          $lineHeight={isMobile ? 33.41 : 47.73}
          color={colors.BLACK}
          fontWeight="ExtraBold"
        >
          연말정산이 간편해집니다.
        </Typograpy>

        <Typograpy
          fontSize={isMobile ? 14 : 18}
          $lineHeight={isMobile ? 16.71 : 21.48}
          color={"#898989"}
          fontWeight="Medium"
        >
          단, 한번으로 최대 환급금을 만듭니다.
        </Typograpy>

        <Box
          $isPointer
          $bgcolor={colors.MAIN}
          $br={8}
          $px={5}
          $py={isMobile ? 1.5 : 2}
        >
          <Typograpy
            fontSize={isMobile ? 14 : 16}
            $lineHeight={isMobile ? 16.71 : 19.09}
            fontWeight="Bold"
            color={colors.WHITE}
          >
            BLUE BUTTON
          </Typograpy>
        </Box>
      </FlexBox>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        }}
      >
        <FlexBox $flex={0} $ai="center" $jc="center">
          <Img
            src={require("assets/img/rending1.jpg")}
            width={"100%"}
            style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
          />
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
