import Typograpy from "components/Typograpy";
import React from "react";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

export const ServiceAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        서비스 이용약관
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제1조 (목적)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`이 약관은 회원이 주식회사 블루라이언스(이하 '회사'라 합니다)가 제공하는 서비스(이하 "서비스"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무, 책임사항, 서비스 이용조건 및 절차 등 기본적인 사항을 정함으로써 상호간에 권리∙의무 관계를 명확히 하는 것을 목적으로 합니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제2조 (용어의 정의)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
 1. "회원"이란 본 약관에 따라 회원 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를
    이용하는 자(개인)인 혹은 법인을 말합니다. 온라인을 통해 회원가입을 하고, 이 약관에 따라
    회사와 이용계약을 체결하여 유무상으로 서비스를 사용하는 고객을 말합니다.
 2. "서비스"란 개인이 근로소득원천징수영수증, 연말정산 신고 자료, 종합소득내역 및 신고서등 이에 
    준하는 국세청 고시, 부가 및 부속 서류로써, 개인의 소득정보, 소비정보, 근로정보, 세금정보
    및 관련 업무를 모바일 및 PC 온라인 상으로 간편하게 이용할 수 있도록 회사가 제공하는 일체의
     서비스를 말합니다.
 3. "이용료"라 함은 회사에서 제공하는 기본 서비스 및 부가서비스, 세부정보 서비스등의 유료화된
     서비스의 이용료를 말합니다.
 5. "파트너사"라 함은 회사와 계약 혹은 협약된 은행 및 보험사, 증권사, 일반기업, 마케팅 대행사,
    법인보험 대리점, 혹은 법인 보험 모집 대행사등을 말하며, 회사의 추천기능을 이용하여 관련
    금융상품, 보험상품등의 가입업무를 진행하는 경우 해당 업무를 위임받은 은행, 증권사, 구인구직
    업체들, 파트너사 및 보험대리점 법인들을 말합니다.
 6. "(이용료) 환불" 이라 함은 회원이 결제했던 내역을 취소하는 것을 말합니다.
 7. "(세금) 환급" 이라 함은 회원이 신고를 통해 기납부한 세금에서 전부(또는 일부)를 국가(국세청, 
    지방자치단체)로부터 돌려받는 것을 말합니다.
 8. "수수료" 이라 함은 회원이 추천상품을 이용 할 경우 파트너사가 당사에 지불하는 금원을
    말합니다. 
  ① 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 관련 법령에 정한 바에 따릅니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제3조 (약관의 게시, 해석, 변경 등)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 회원이 서비스를 이용하기 전에 본 약관을 게시하고, 회원이 본 약관의 중요한 내용을 확인
   할 수 있도록 합니다.
② 이 약관의 해석 또는 이 약관에서 정하지 않은 사항 등에 대하여 관련법령에 따릅니다.
③ 회사는 이 약관의 내용을 변경하고자 하는 경우 변경사항의 시행 7일 전부터 회원에게 홈페이지를
   통해 공지합니다. 다만, 이용자에게 불리하거나 중대한 사항의 변경은 최소한 30일 이전에 통지합
   니다. 통지의 방법은 당사 홈페이지등을 이용한 방법을 사용합니다.
④ 회원은 변경된 약관이 공지된 후 15일 이내에 거부의사를 표명할 수 있습니다. 회원이 거부하는
   경우 회사는 15일의 기간을 정하여 회원에게 사전 통지 후 당해 회원과의 계약을 해지할 수 있습
   니다. 만약, 회사가 개정약관 공지(통지)시 회원이 거부의사를 표시 하지 않고 서비스를 계속 이용
   하는 경우 동의한 것으로 간주하겠다는 내용도 함께 공지(통지)하였음에도 회원이 거부의사를 표시
   하지 않고 서비스를 계속 이용하는 경우에는 개정약관에 동의하는 것으로 간주합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제4조 (이용계약의 체결)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 서비스 이용계약(이하 '계약'이라 합니다)은 회원이 되려는 자(이하 '가입신청자'라 합니다)가 이 
   약관의 내용에 동의하고 회사가 이를 승낙함으로써 체결됩니다.
② 전항의 승낙은 회사가 정하는 통지 등의 방법으로 표시하며, 이 표시가 가입 신청자에게 도달한 
   시점에 계약이 성립된 것으로 봅니다.
③ 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 가입신청을 유보하거나 거부할 수 있으며, 
   추후 계약을 해지할 수 있습니다. 이 때 회사는 각 호의 내용을 판단하기 위하여 가입 신청자나 
   회원에게 적절한 자료를 요청할 수 있습니다.
  1. 가입신청자가 서비스를 이용할 수 있는 정상적인 개인이 아닌 경우
  2. 가입신청자가 가입신청에 필요한 정보를 제공하지 않거나 제공한 정보가 거짓인 경우
  3. 가입신청자가 대리인으로서 신청하는 때에 적법한 대리권을 갖지 못한 경우
④ 회사는 기술상 문제, 인력의 부족 등을 이유로 가입신청의 승낙을 유보할 수 있습니다.
⑤ 회사는 가입신청이 제3항 또는 제4항에 해당하여 유보, 거부 또는 계약의 해지가 된 때에는 특별
   한 사정이 없는 한 그 사실을 가입 신청자 또는 회원에게 알려야 합니다.
⑥ 회사는 만 14세 미만 아동에게 당사의 서비스를 제공하지 않으며 이와 관련한 개인정보를 수집하
   지 않습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제5조 (서비스의 내용)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 서비스의 세부 내용은 다음 각호와 같습니다.
1. 회원이 서비스에 제공한 정보의 가공, 처리 및 저장
2. 회원이 위 각호의 정보를 제공하고 열람하는데 필요한 온라인 플랫폼 및 모바일 플랫폼의 제공
3. 회원과 회사가 위 각호 업무처리에 필요한 커뮤니케이션 수단
② 회사는 회원의 동의 없이 전항 각호의 서비스를 거부할 수 없으며, 마찬가지로 회원은 별개의 계약
   없이 전항 각호 외의 서비스를 요구할 수 없습니다
③ 제1항 각호 외에 추가 서비스가 필요한 경우, 회원은 이용계약의 체결과 동시에 또는 체결 후에 
   이 약관과 별도로 제시되는 약관에 따라 계약을 체결할 수 있습니다.
④ 회사는 서비스를 통해 회원의 세금 납부/환급 관련 신고를 대리하지 않습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제6조 (서비스의 중단)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 회원이 언제든지 서비스를 이용할 수 있도록 운영해야 합니다.
② 전항에도 불구하고 회사는 다음 각호의 어느 하나에 해당하는 때에는 일정 시간 동안 서비스의 
   제공을 중단할 수 있습니다.
1. 시스템 정기점검 또는 업그레이드를 위한 경우
2. 회원이 제7조에 따른 이용료를 결제하지 않은 경우
3. 회원이 서비스 제공 및 업무처리를 거부하는 경우
4. 정부기관 등 관련 외부서비스의 시스템 정기 점검 및 서비스 제공 불가능한 경우
5. 기타 천재지변 등으로 정상적인 서비스 제공이 불가능한 경우

③ 회사는 전항 제1호 내지 제5호의 중단이 예정되는 때에는 미리 그 사유와 기간을 회원에게 알려야
   합니다. 전항의 중단은 그 사유가 종료되는 즉시 회원에게 알려야 합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        ​​제7조 (이용료 등)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 회원에게 서비스 이용에 대한 대가로 서비스 이용료를 청구할 수 있습니다.
② 이용료는 카드 결제, 간편 결제(토스, 카카오 페이, 네이버페이 등)를 원칙으로 합니다.
③ 회사는 회원이 이용료 및 신고 수수료를 결제 할 수 있는 방법을 제공할 수 있습니다. 
   다만, 결제와 관련하여 회원, 전자결제대행업체, 금융기관 등과의 사이에서 발생한 분쟁은 당사자들
   간의 해결을 원칙으로 하며, 회사는 회사의 고의 또는 중과실이 없는 한 이와 관련해서 어떠한 
   책임도 지지 않습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제8조 (환불)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회원은 다음 환불 정책에 따라 회사에 이용료 환불을 요구할 수 있습니다.
① 회사가 서비스를 위해 회원 개인의 데이터를 국세청 홈텍스로부터 스크래핑 하지 못하고, 데이터를
   제공하지 않을 때, 환불 신청시 모두 전액 환불이 가능합니다. 단, 회원개인의 데이터가 국세청 
   홈텍스 및 기타 정부기관, 금융사등에서 스크래핑 되어 데이터가 시각화를 마친 경우 환불되지 
   않습니다.
② 제1항에 해당 되지 않는 사항의 경우는 다음과 같이 적용합니다.
  1. 회사는 개별 서비스의 성격에 따라 개별 약관 및 이용 조건에 따른 환불 규정을 정할 수 있으며, 
    이 경우 개별 약관 및 이용 조건 상의 취소 및 환불정책이 우선 적용됩니다.
  2. 본 서비스 이용약관에 규정되지 않은 환불 사항은 전자상거래법 규정에 따릅니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제9조 (회사의 의무)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 회원에게 안정적인 서비스를 계속 제공하기 위하여 노력하고, 이 약관 및 관련 법령의 내용
   을 준수해야 합니다.
② 회사는 전항의 의무를 다하기 위하여 최적의 인력 및 시스템, 보안 등을 유지합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제10조 (회사의 책임제한)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비
   스 제공에 관한 책임이 면제됩니다.
② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 고의 또는 중과실이 없는 한 책임
   을 지지 않습니다.
③ 회사는 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 고의
   또는 중과실이 없는 한 책임을 지지 않습니다.
④ 타인의 명의, 계정 등을 도용하여 회사가 제공하는 서비스를 이용하는 경우 회사의 고의 또는 중과
   실이 없는 한 책임이 면제됩니다.
⑤ 회사는 정부정책 및 제도, 법률에 의거 개인의 데이터를 스크래핑 하지 못하는 것에 대한 제한된 
   서비스의 영역에 대하여 책임이 면제됩니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제11조 (회원의 의무)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회원은 서비스 관련 업무를 원활하게 진행하기 위해 회사가 또는 파트너사들이 요구하는 정확한
   정보를 제공해야 합니다.
② 회원은 서비스를 이용함에 있어 다음 각 호의 행위를 하여서는 아니됩니다.
1. 본 약관 및 서비스 이용에 관련된 관계법령을 위반한 행위
2. 서비스를 제공 받기 위해 이용 신청 또는 변경 신청 시 허위 사실을 기재하거나 타인의 정보를 도용하는 등 정상적인 서비스 운영을 고의로 방해하는 일체의 행위
3. 회사가 정상적으로 제공하는 방법이 아닌 다른 방법으로 회사가 보유하고 있는 정보를 탈취, 저장, 공개 또는 부정한 목적으로 사용하는 행위
4. 회사의 지식재산권, 제3자의 지식재산권 등 기타 권리를 침해하거나, 회사의 동의 없이 회원 또는 제3자의 상업적인 목적을 위하여 본 서비스 구성요소의 전부 또는 일부의 내용에 관하여 이를 복사, 복제, 판매, 재판매 또는 양도하는 행위
5. 기타 범죄 또는 법령이 금지하는 행위와 관련되었다고 의심받을 수 있는 일체의 행위
6. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
7. 회사의 서비스를 해킹하거나 해킹에 이용하는 일체의 행위
8. 기타 불법적이거나 부당한 행위`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제12조 (회원의 서비스 이용제한)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회원의 서비스 이용 중 다음 각 호의 사유가 발생하는 경우, 회사는 상당한 기간 전에 최고하고 
   기간을 정하여 해당 회원의 서비스 이용을 제한 또는 정지하거나 이용계약 해지 등의 조치를 취할
   수 있습니다.
  1. 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나
    회사의 시스템에 접근하는 행위
  2. 타인의 명의, 카드정보, 계좌정보 등을 도용하여 회사가 제공하는 서비스를 이용하는 행위
3. 회원이 회사의 서비스 운영을 고의로 방해하는 경우
4. 다른 회원의 정당한 이익을 침해하거나 법령에 위배 되는 행위를 한 경우
5. 본 약관에 위배 되는 행위를 한 경우
6. 본 약관에서 규정한 사항이나 별도 페이지에 규정한 이용정책을 위반한 경우

② 회원이 본 조의 금지행위를 하는 경우 서비스 이용을 제한함과 별도로 손해배상의 청구, 사법당국
   에 대한 고발 등 법적 조치를 취할 수 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제13조 (개인정보의 보호 및 이용)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 개인정보의 보호 및 처리와 관련하여 개인정보보호법, 정보통신망 이용 촉진 및 정보보호에
   관한 법률 및 각 그 하위 법령에 정하는 사항을 준수하며, 개인정보의 보호를 위하여 노력합니다.
② 회사는 개인정보의 수집, 이용, 제공, 보호, 위탁 등에 관한 제반 사항의 구체적인 내용을 개인정보
   처리방침을 통하여 규정하며, 개인정보처리방침은 회사의 웹페이지, 앱 서비스 화면 등을 통하여 
   게시합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제14조 (계약의 해지)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회원은 언제든지 회사에 이용계약 해지의 의사를 통지함으로써 이용계약을 해지할 수 있습니다.
② 당사자 일방이 계약에 규정된 의무를 위반하거나 불성실하게 이행하는 경우, 상대방은 그 시정이나
   개선을 요구할 수 있으며, 그에 대하여 14일 이내에 귀책당사자의 시정 또는 개선조치가 없는 
   경우에 상대방은 전자우편 또는 서면에 의한 통지로써 이 약관을 해지할 수 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제15조 (손해배상)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 서비스의 결함으로 인하여 회원에게 손해가 발생한 경우 회원에게 실제 발생한 손해를 
   배상합니다. 단, 회사의 고의 또는 과실 없이 회원에게 발생한 일체의 손해에 대하여는 책임을 
   지지 아니합니다.
② 회사는 이 약관에서 명시되지 아니한 회사의 귀책사유로 인하여 유료서비스의 이용 회원에게 손해
   가 발생한 경우 회사의 배상 책임은 관련법령 및 상관례를 따릅니다.
③ 회원이 이 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 서비스를
   이용함에 있어 회사에 손해를 입힌 경우, 회원은 회사에 그 손해를 배상하여야 합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제16조 (회원에 대한 통지)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 이 약관에 따라 회원에게 일정한 사항을 알릴 때에는 회원이 등록한 전자우편, 서비스 공지사항,
   메시지, 문자 등을 사용합니다.
② 전항에도 불구하고 회원 전체를 대상으로 하거나 긴급한 사유가 발생한 경우에는 메일 혹은 문자
   및 회사가 정한 방법을 통하여 알릴 수 있습니다.
③ 회사는 회사의 고의 또는 중과실이 없는 이상, 회원이 고의 또는 과실로 본 조의 통지를 인지하지
   못한 경우에 발생하는 손해에 대하여 책임을 지지 않습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제17조 (광고성 또는 맞춤형 정보의 제공)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 회사는 본 서비스의 운영과 관련하여 광고성 정보를 제공하는 서비스를 운영할 수 있습니다.
② 회사는 광고성 정보 및 회원 맞춤형 정보를 문자나 푸쉬 메시지 형식 등으로 제공할 수 있으며, 
   이는 광고성정보에 대한 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제18조 (분쟁해결)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 이 약관과 관련하여 분쟁이 발생하는 경우 각 당사자는 원만한 해결을 위하여 서로 협력합니다.
② 당사자 간에 제기된 소송은 대한민국법을 준거법으로 하며, 관련법령 또는 양 당사자 합의에 따른
   법원을 관할법원으로 합니다.

- 부칙 -
이 약관은 2024년 09월 15일부터 적용됩니다.`}
      </Typograpy>
    </FlexBox>
  );
};
