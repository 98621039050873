export interface FontStyleElType {
  fontSize: string;
  $lineHeight: string;
  letterSpacing: string;
}

export const fontStyle = {
  display1: `
    font-size: 5.6rem;
    line-height: 7.2rem;
    letter-spacing: -0.0319rem;
  `,
  display2: `
    font-size: 4rem;
    line-height: 5.2rem;
    letter-spacing: -0.0282rem;
  `,
  title1: `
    font-size: 3.6rem;
    line-height: 4.8rem;
    letter-spacing: 0.027rem;
  `,
  title2: `
    font-size: 2.8rem;
    line-height: 3.8rem;
    letter-spacing: 0.0236rem;
  `,
  title3: `
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.023rem;
  `,
  heading1: `
    font-size: 2.2rem;
    line-height: 3rem;
    letter-spacing: 0.0194rem;
  `,
  heading2: `
    font-size: 2rem;
    line-height: 2.8rem;
    letter-spacing: 0.012rem;
  `,
  headline1: `
    font-size: 1.8rem;
    line-height: 2.6rem;
    letter-spacing: 0.002rem;
  `,
  headline2: `
    font-size: 1.7rem;
    line-height: 2.4rem;
    letter-spacing: 0;
  `,
  body1_normal: `
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.0057rem;
  `,
  body1_reading: `
    font-size: 1.6rem;
    line-height: 2.6rem;
    letter-spacing: 0.0057rem;
  `,
  body2_normal: `
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 0.0096rem;
  `,
  body2_reading: `
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.0096rem;
  `,
  label1_normal: `
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.0145rem;
  `,
  label1_reading: `
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.0145rem;
  `,
  label2: `
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: 0.0194rem;
  `,
  caption1: `
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.0252rem;
  `,
  caption2: `
    font-size: 1.1rem;
    line-height: 1.4rem;
    letter-spacing: 0.0311rem;
  `,
};
