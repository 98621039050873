import React from "react";
import logo from "./logo.svg";
import { RouterProvider, useLocation } from "react-router-dom";
import "./App.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { MasterRouter } from "router/MasterRouter";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={MasterRouter} />
    </QueryClientProvider>
  );
}

export default App;
