import { CloseIc, LogoIc, MenuIc } from "components/icon/icon";
import Typograpy from "components/Typograpy";
import { Mobile, PC } from "func/responsive";
import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { menuList } from "./menuList";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const BlurHeaderWrapper = styled(FlexBox)`
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
  }
`;

export const Header = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const pathName = location.pathname.split("/")[1];

  const [isMenuVisible, setIsMenuVisible] = React.useState(false);

  const controls = useAnimation();

  const variants = {
    //subside 토글
    hidden: { x: 400, opacity: 0, transition: { duration: 0.5 } },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  React.useEffect(() => {
    if (isMenuVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isMenuVisible]);

  //sidebar (mobile에서) 활성화시 body 스크롤 lock
  React.useEffect(() => {
    if (isMenuVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuVisible]);

  return (
    <Box
      $isFullWidth
      $position={"sticky"}
      $top={0}
      $left={0}
      style={{ zIndex: 3 }}
    >
      <PC>
        <BlurHeaderWrapper $isFullWidth $ai="center" height={8.6}>
          <FlexBox
            $isFullWidth
            $px={2}
            $maxWidth={MainMaxWidth}
            $flex_direction="row"
            $ai="center"
            $py={1.2}
            $flex={1}
          >
            <FlexBox $flex={1} $isFullWidth>
              <Box
                $isPointer
                onClick={() => {
                  navigate("/");
                }}
              >
                <LogoIc width={6} height={6} />
              </Box>
            </FlexBox>
            <FlexBox $flex_direction="row" $gap={5} $flex={1.5} $isFullWidth>
              {menuList.map((item, index) => {
                return (
                  <Box
                    $isPointer
                    key={"header-menu" + index}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    <Typograpy
                      fontSize={18}
                      $lineHeight={21.48}
                      color={
                        pathName === item.link
                          ? colors.MAIN
                          : colors.DEFAULT_FONT_COLOR
                      }
                      fontWeight={pathName === item.link ? "Bold" : "SemiBold"}
                    >
                      {item.label}
                    </Typograpy>
                  </Box>
                );
              })}
            </FlexBox>
          </FlexBox>
        </BlurHeaderWrapper>
      </PC>
      <Mobile>
        {/* <BlurHeaderWrapper> */}
        <FlexBox
          $isFullWidth
          $px={2}
          height={6.5}
          $flex_direction="row"
          $jc="space-between"
          $ai="center"
          $bgcolor={colors.WHITE}
        >
          <Box
            $isPointer
            onClick={() => {
              navigate("/");
              setIsMenuVisible(false);
            }}
          >
            <LogoIc />
          </Box>
          <Box
            $isPointer
            onClick={() => {
              setIsMenuVisible(!isMenuVisible);
            }}
          >
            {isMenuVisible ? <CloseIc /> : <MenuIc />}
          </Box>
        </FlexBox>
        {isMenuVisible && (
          <motion.div
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            style={{ width: "100%" }}
          >
            <FlexBox
              $isFullWidth
              $pt={2}
              $bgcolor={colors.WHITE}
              height={"calc(100vh - 6.5rem)"}
            >
              {menuList.map((item, index) => {
                return (
                  <Box
                    $isPointer
                    $isFullWidth
                    key={"header-menu" + index}
                    onClick={() => {
                      navigate(item.link);
                      setIsMenuVisible(false);
                    }}
                    $pa={2}
                    $bbw={1}
                    $bbc={colors.DEFAULT_BORDER_COLOR}
                  >
                    <Typograpy
                      fontSize={18}
                      $lineHeight={21.48}
                      color={
                        pathName === item.link
                          ? colors.MAIN
                          : colors.DEFAULT_FONT_COLOR
                      }
                      fontWeight={pathName === item.link ? "Bold" : "SemiBold"}
                    >
                      {item.label}
                    </Typograpy>
                  </Box>
                );
              })}
            </FlexBox>
          </motion.div>
        )}
        {/* )} */}
        {/* </BlurHeaderWrapper> */}
      </Mobile>
    </Box>
  );
};
