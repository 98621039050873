import styled from "styled-components";
import { colors } from "theme/colors";

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  background-color: ${colors.WHITE};
  box-sizing: border-box;

  outline: none;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 20rem;
  padding: 1.5rem;
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  background-color: ${colors.WHITE};
  box-sizing: border-box;
  resize: none;

  outline: none;
`;

export { Input, TextArea };
