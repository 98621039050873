import { Intro } from "components/section/rending/Intro";
import { InduceStore } from "components/section/rending/InduceStore";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { FlexBox } from "theme/globalStyle";
import { BlueButton } from "components/section/blueButton/BlueButton";

export const MainPage = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  return (
    <FlexBox $isFullWidth>
      <Intro />
      <InduceStore />
      <BlueButton />
    </FlexBox>
  );
};
