import Typograpy from "components/Typograpy";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { AppleIc, GooglePlayIc } from "components/icon/icon";
export const InduceStore = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, y: 50 }, //초기엔 y위치가 50
    visible: {
      opacity: 1,
      y: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
      },
    },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      // $pt={isMobile ? 0 : 8.6}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 10}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
    >
      <FlexBox $flex={0} $gap={3} $ai={isMobile ? "center" : "flex-start"}>
        <FlexBox $ai={isMobile ? "center" : "flex-start"}>
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLACK}
            fontWeight="ExtraBold"
          >
            스마트폰에서
          </Typograpy>
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLACK}
            fontWeight="ExtraBold"
          >
            무료로 사용하세요
          </Typograpy>
        </FlexBox>
        <FlexBox $ai={isMobile ? "center" : "flex-start"}>
          <Typograpy
            fontSize={isMobile ? 14 : 18}
            $lineHeight={isMobile ? 16.71 : 21.48}
            color={"#898989"}
            fontWeight="Medium"
          >
            연말정산의 분석과 예측, 환급금 조회 청구,
          </Typograpy>
          <Typograpy
            fontSize={isMobile ? 14 : 18}
            $lineHeight={isMobile ? 16.71 : 21.48}
            color={"#898989"}
            fontWeight="Medium"
          >
            월급관리 블루버튼 서비스는 싹다~{" "}
            <Typograpy
              fontSize={isMobile ? 14 : 18}
              $lineHeight={isMobile ? 16.71 : 21.48}
              color={colors.MAIN}
              fontWeight="Bold"
            >
              무료
            </Typograpy>
            입니다.
          </Typograpy>
        </FlexBox>
        <FlexBox $flex_direction="row" $gap={1.2}>
          <FlexBox
            $isPointer
            $bgcolor={colors.GOOGLE_PLAY_BG}
            $br={8}
            $py={isMobile ? 1.5 : 2}
            width={isMobile ? 15 : 24}
            $ai="center"
            $jc="center"
            $flex_direction="row"
            $gap={0.8}
          >
            <GooglePlayIc
              width={isMobile ? 1.6 : 1.8}
              height={isMobile ? 2 : 1.8}
            />
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.BLACK}
            >
              Google Play
            </Typograpy>
          </FlexBox>
          <FlexBox
            $isPointer
            $bgcolor={colors.BLACK}
            $br={8}
            $py={isMobile ? 1.5 : 2}
            width={isMobile ? 15 : 24}
            $ai="center"
            $jc="center"
            $flex_direction="row"
            $gap={0.8}
          >
            <AppleIc
              width={isMobile ? 1.44 : 1.6}
              height={isMobile ? 1.8 : 2}
            />
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.WHITE}
            >
              App Store
            </Typograpy>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
        style={{ width: "80%" }}
      >
        <FlexBox $flex_direction="row" $ai="center" $gap={1.2}>
          <motion.div style={{ flex: 1 }} variants={itemVariants}>
            <Box $flex={1}>
              <Img
                src={require("assets/img/induce_store1.png")}
                width={"100%"}
              />
            </Box>
          </motion.div>
          <FlexBox $flex={1}>
            <motion.div variants={itemVariants}>
              <Img
                src={require("assets/img/induce_store2.jpg")}
                width={"100%"}
              />
            </motion.div>
            <motion.div variants={itemVariants}>
              <Img
                src={require("assets/img/induce_store3.jpg")}
                width={"100%"}
              />
            </motion.div>
          </FlexBox>
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
