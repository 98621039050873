import Typograpy from "components/Typograpy";
import React from "react";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

export const MarketingAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        마케팅 정보 수신동의 약관
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`㈜블루라이언스(이하 “회사”)은 회사가 제공하는 블루버튼 서비스(이하 “서비스”)를 운용함에 있어 각종 정보를 SMS, 이메일 등의 방법으로 이용자에게 제공할 수 있으며, 결제 안내 등 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제1조. 마케팅 정보 수신동의 목적
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 마케팅 활용 목적
- 새로운 콘텐츠 및 기능의 안내 또는 이벤트 및 프로모션, 상품정보 등 광
  고성 정보 제공을 위해 본 약관에 동의하신 이용자의 개인정보를 활용합
  니다.

(2) 전송방법
- SMS, 이메일 등으로 마케팅 정보를 전달합니다.

(3) 철회안내
- 수신 동의 후에도 이용자 본인의 의사에 따라 동의를 철회할 수 있으며, 
  수신을 동의하지 않아도 “회사”가 제공하는 기본적인 서비스를 이용할 수 
  있으나, 당사의 마케팅 정보를 수신하지 못할 수 있습니다.

(4) 수신 동의변경
- 1:1문의 접수 혹은 사이트 내 계정 정보 페이지를 통해 수신 동의를 변경  
  (동의/철회)할 수 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제2조. 개인정보 이용 상세내용
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`- 이용하는 개인정보: 휴대전화번호, 이메일 주소
- 보유 및 이용 목적: 새로운 콘텐츠 및 기능 안내, 이벤트 및 프로모션, 상품정보 등 
  정보 제공`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제3조. 개인정보의 보유 및 이용 기간
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`- 정보통신망법 제50조 제8항에 따라 회원 탈퇴 또는 마케팅 동의 철회 등의 경우를 제외
  하고 마케팅 활용 동의일로부터 최대 2년 간 보유 및 이용됩니다.
- 본 마케팅 활용 및 광고성 정보수신 동의 항목은 선택 사항이므로 개인정보보호법 제22조 
  제4항에 의해 동의를 거부하는 경우에도 “회사”가 제공하는 서비스 이용에는 영향이 없습
  니다. 다만, 동의하지 않을 경우 마케팅 활용 및 광고성 정보 수신 동의를 통해 제공 가능
  한 각종 혜택, 이벤트 안내를 받아 보실 수 없습니다.
- “회사”는 이용자의 마케팅 활용 동의일로부터 2년마다 계정에 등록된 이메일을 통하여 공
  지합니다.
- “회사”가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 시행일까지 거부
  의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 별도의 
  의사표시가 없는 경우에는 약관을 승인한 것으로 보며, 약관에 동의하지 않을 경우 제1조
  제4항에 따라 자유롭게 철회할 수 있습니다.
`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제4조. 개정 전 고지 의무
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`본 약관은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용 추가, 삭제 및 정정과 수집하는 개인정보의 항목, 이용 목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 경우에는 웹사이트 또는 서비스 상의 공지 또는 이메일을 통하여 고지할 것이며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.

- 공고일자: 2024년 09월 15일
- 시행일자: 2024년 09월 15일`}
      </Typograpy>
    </FlexBox>
  );
};
