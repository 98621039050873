import Typograpy from "components/Typograpy";
import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface TableTdProps {
  $tdWidth?: string | number;
}

const TableRowWrapper = styled(FlexBox)`
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid ${colors.DEFAULT_BORDER_COLOR};
`;

const AgreeTableWrapper = styled.table`
  /* border : 1px solid ${colors.DEFAULT_BORDER_COLOR}; */

  width: 100%;
  height: auto;
  border-collapse: collapse;
`;

const AgreeTableHeader = styled.th<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  width: 100%;
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
`;

const AgreeTableTr = styled.tr`
  width: 100%;
  /* border: 1px solid ${colors.DEFAULT_BORDER_COLOR}; */
`;

const AgreeTableTd = styled.td<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
`;

export const PrivacyAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        개인정보처리방침(블루버튼)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`주식회사 블루라이언스(이하 “회사”)는 “개인정보 보호법”, “전자상거래 등에서의 소비자보호에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련 법령에 따라 이용자의 개인정보를 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 개인정보처리방침에 따라 개인정보를 처리하고 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        1. 개인정보의 처리 목적
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 서비스 이용에 필수적인 개인정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 처리 목적과 항목이 변경되는 경우에는 사전에 이용자의 동의를 받습니다.

(1) 서비스 제공에 관한 계약이행 및 요금 정산
1. 연말정산 간소화 데이터, 전체소득 데이터, 근로소득원천징수영수증, 원천세납부영수증등 그 
부속서류 스크래핑 및 데이터 계산 및 예측, 및 시각화
2. 제휴사의 세무,보험,금융상품등의 가입에 대한 서비스 지원
3. 제휴사의 세무,보험,금융상품등의 상담을 위한 서비스 지원
4. 정기·비정기 대금 자동 또는 수동 결제 등

(2) 회원 관리
1. 회원제 서비스 이용에 따른 본인 확인 및 법인·개인 식별
2. 회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인
3. 불만 및 민원처리, 고지사항 전달, 광고성 정보 제공 및 참여기회 제공
4. 이용기록 분석 및 통계분석 등`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        2. 개인정보의 수집/처리 항목
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 회원가입, 서비스의 이용, 고객상담, 분석 등의 각종 서비스 제공을 위해 아래와 같은 정보를 처리합니다.`}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"10%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              구분
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"10%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              동의구분
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              수집/처리 항목
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              수집/처리 목적
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              수집/처리 이용기간
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd rowSpan={2} style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              회원가입시점 (간편인증가입,카카오 가입, SNS가입)
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              필수
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`프로필 정보 (닉네임/프로필 사진), 이메일, 전화번호,주소`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd rowSpan={2}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`- 회원식별 및 회원관리, 고객상담 및 민원처리
- 부정 이용 방지와 비인가 사용 방지
- 광고성 정보 제공 및 참여기회 제공
- 부가서비스 제공 및 해제(서비스 가입자에 한함)
- 기타 법률에서 정한 목적`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd rowSpan={4}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`서비스 해지 또는 회원 탈퇴 즉시 파기 (단, 관련법규에 의해 별도 규정이 있는 경우 그 기간을 따름)

홈택스 간편인증시 입력받은 주민등록번호는 스크래핑 조회 후 즉시 파기`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              선택
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`이름, 출생연도`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`블루버튼 서비스 이용 과정

(홈택스 간편인증) `}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`필수`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`- 이름, 전화번호, 주민등록번호
- 홈택스 정보제공동의 정보
- 부가가치세 과세정보
- 사업자등록정보
- 소득세 과세정보
- 사업장 제공자 등 과세자료 제출명세서 정보
- 일용근로소득지급명세서 정보
- 간이지급명세서 정보
- 연말정산 간소화 정보
- 원천세 과세정보
- 편리한 연말정산 정보
- 현금영수증 정보
- 국세 징수, 환급 정보
- 국세청 대민서비스 사용자 정보
- 근로소득원천징수영수증 정보`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`- - 소득정보,소비정보,근로정보,세금 신고 서비스 분석 서비스 제공
- 각종 소득,소비,근로,세금 관련 서비스 제공
- 이용자 식별 진행
- 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리, 추후 분쟁조정을 위한 기록보존, 고지사항 전달 등`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 서비스 이용 과정에서 자동 생성/수집되는 정보
• IP 주소, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기정보
• 결제 서비스 이용 중 생성되는 정보
• 고객 상담 중 생성되는 정보

(2) 개인정보 수집 방법
- 서비스 이용을 위해 회사는 다음과 같은 방법으로 고객의 개인정보를 수집하고 있습니다.
• 회원가입 및 서비스 이용을 위해 고객이 직접 입력한 정보
• 서비스 이용을 위해 회원의 동의를 받아 스크래핑 기술을 이용하여 조회한 홈택스, 금융기관
등의 결과 정보
• 상담과 민원처리 과정에서 수집/생성된 정보
• 온오프라인의 이벤트/설문 등을 통한 수집
• 서비스 이용과정에서 자동으로 수집되는 정보`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        3. 개인정보의 보유기간
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 이용자가 동의한 개인정보의 보유기간 내에서 개인정보를 처리합니다. 회원이 탈퇴를 요청하거나 개인정보 수집·이용에 대한 동의를 철회하는 경우, 수집·이용 목적이 달성되거나 보유기간이 종료한 경우에는 해당 개인정보를 지체 없이 파기합니다. 단, 다음의 항목에 대해서는 관련 법령의 규정에 따라 아래에 명시한 기간 동안 보관할 수 있습니다.`}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"40%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              법령
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"40%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              항목
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"20%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              보유기간
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd rowSpan={4} style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              전자상거래 등에서의 소비자보호에 관한 법률
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              소비자 불만 또는 분쟁처리에 관한 기록
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`3년`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              계약 또는 청약철회 등에 관한 기록
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`5년`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`대금결제 및 재화등의 공급에 관한 기록`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`5년`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`표시/광고에 대한 기록`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`6개월`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`국세기본법`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`세법이 규정하는 모든 거래에 관한 장부 및 증빙서류`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`5년`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`전자금융거래법`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`전자금융에 관힌 기록`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`5년`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        4. 개인정보의 위탁
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 다음와 같이 개인정보 처리 업무를 일부 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다.`}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"40%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              위탁받는 자
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"40%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              위탁하는 업무의 내용
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"20%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              보유기간 및 이용기간
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`주식회사 카카오
네이버 주식회사
통신사 패스`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              간편인증
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd rowSpan={6} style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`회원 탈퇴 시 혹은 위탁 계약 종료 시까지`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`토스페이먼츠(주)
NICE페이먼츠(주)
NHN한국사이버결제(주)`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`결제관련 업무대행`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`아임포트(주)`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`결제 연동 서비스`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`엔에이치엔(주)
네이버클라우드(주)
써머스플랫폼(주)
스티비(주)`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`알림톡, 문자, 이메일 발송 서비스`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`금융결제원`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`금융인증서를 통한 홈택스 인증`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`Amazon Web Service Inc`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`개인정보가 저장된 국내(한국 리전) 클라우드 서버 운영 및 관리`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`2. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한, 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보만 위탁하고 있습니다.

개인정보 제3자 제공
회사는 서비스 이용에 반드시 필요한 개인정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 사전에 이용자의 동의를 받습니다.`}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"10%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공받는 제3자
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공하는 개인정보 항목
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공받는 자의 이용목적
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              보유기간
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`서비스 이용시
제휴 파트너사 `}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`- 이름, 전화번호
- 주민등록번호
- 부가가치세 과세정보
- 사업자등록정보
- 소득세 과세정보
- 사업장 제공자 등 과세자료 제출명세서 정보
- 연말정산 간소화 정보
- 원천세 과세정보
- 편리한 연말정산 정보
- 현금영수증 정보
- 근로소득원천징수영수증 정보
- 국세청 대민서비스 사용자 정보`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`- 서비스 제공을 위한 데이터 분석 확인`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`서비스 해지 또는 회원 탈퇴 즉시 파기 (단, 관련법규에 의해 별도 규정이 있는 경우 그 기간을 따름)`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        6. 회원의 권리와 의무
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회원의 권리
• 이용자는 본인 확인 절차를 거친 후, 언제든지 등록되어 있는 자신의 개인정보를 직접 조회, 수정, 해지를 진행할 수 있습니다.
• 직접 진행이 어려운 경우, 서면, 전화, 이메일 또는 고객센터를 통해 문의할 수 있습니다.
• 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.
• 회사는 만 14세 미만 아동에게 당사의 서비스를 제공하지 않으며 이와 관련한 개인정보를 수집하지 않습니다.
(2) 회원의 의무
• 이용자가 입력한 부정확한 정보로 인해 발생하는 문제의 책임은 이용자에게 있으며, 타인 정보를 도용하거나 허위정보를 입력할 경우 회원자격이 상실과 함께 “정보통신망이용촉진 및 정보 보호등에관한법률” 등에 의해 처벌받을 수 있습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        7. 자동 수집되는 개인정보 및 거부
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`서비스 이용과정에서 쿠키, 서비스 이용 기록, 기기정보가 생성되어 수집될 수 있습니다. 서비스 이용 기록은 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있으며, 기기정보는 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환한 후에 수집하는 경우를 의미합니다.

이용자는 쿠키에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 [도구] > [인터넷옵션] > [개인정보] > [설정]을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 단, 모든 쿠키의 저장을 거부하는 경우, 쿠키를 통해 회사에서 제공하는 자동 로그인기능을 이용할 수 없습니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        8. 개인정보의 파기
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 개인정보 보유기간이 경과하거나 처리목적이 달성되었을 때에는 지체 없이 해당 개인정보
   를 파기합니다.
• 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 보존합니다.
(2) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
• 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고 아래 방법에 따라 개인정보를 파기합니다.
• 파기방법: 회사는 법령에 따라 전자적 파일 형태로 저장된 개인정보는 복구 또는 재생되지 아니하도록 파기하며, 종이 문서에 기록된 개인정보는 복구할 수 없도록 세절하는 방법으로 파기합니다.

(3) 회사는 개인정보 보호법 제39조의6에 따라 장기 미이용자에 관한 정책을 다음과 같이 유지하고
   있습니다.
• 최근 1년 동안 이용 기록이 없는 회원(장기 미이용자)의 개인정보는 별도로 분리하여 안전하게 관리하게 되며, 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에 이메일 주소를 통해 안내를 합니다.
• 단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의 관계법령의 규정에 의하여 보존할 필요가 있는 경우 규정된 기간 동안 고객의 개인정보를 보관합니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        9. 개인정보의 안전성 확보
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 개인정보의 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.
• 관리적 조치: 내부관리계획 수립 및 시행, 직원 대상 정기적 개인정보 보호 교육 등
• 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 
등의 암호화, 보안프로그램 설치 등
• 물리적 조치: 전산실, 자료보관실 등의 접근통제 등`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        10. 개인정보 보호책임자
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보 보호책임자 및 담당자를 지정하고 있습니다.
• [개인정보 보호책임자]
• 이름: 지환국
• 직위: CEO
• 메일: chris@bluelions.kr
• [개인정보 보호담당자]
• 이름: 지환국 
• 소속: 정보보안
• 전화: 051-747-8563
• 메일: chris@bluelions.kr

그 외 개인정보의 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의하실 수 있습니다.
• 개인정보침해 신고센터: (국번없이) 118 / privacy.kisa.or.kr
• 개인정보 분쟁조정위원회: 1833-6972 / kopico.go.kr
• 대검찰청 사이버수사과: (국번없이) 130 / spo.go.kr
• 경찰청 사이버수사국: 경찰민원콜센터 (국번없이) 182 / ecrm.police.go.kr`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        11. 고지의 의무
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`본 개인정보 처리방침은 관련 법령 및 회사 정책변경 등에 따라 개정될 수 있으며, 이 경우 변경사항의 시행 7일 전부터 이메일 또는 홈페이지를 통해 사전 고지할 예정입니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
이 개인정보 처리방침은 2024년 09월 15일부터 적용됩니다.`}
      </Typograpy>
    </FlexBox>
  );
};
