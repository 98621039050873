import { CloudeIc, LockIc } from "components/icon/icon";
import Typograpy from "components/Typograpy";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const BlueButton = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, y: 50 }, //초기엔 y위치가 50
    visible: {
      opacity: 1,
      y: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
      },
    },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $py={isMobile ? 2 : 0}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 10}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
    >
      <FlexBox $ai="center">
        <Typograpy
          fontSize={isMobile ? 26 : 40}
          fontWeight="ExtraBold"
          $lineHeight={isMobile ? 33.41 : 47.73}
          color={colors.BLACK}
        >
          블루버튼의 개인정보는
        </Typograpy>
        <FlexBox
          $ai={"center"}
          $gap={isMobile ? 0.1 : 1.2}
          $flex_direction={isMobile ? "column" : "row"}
        >
          <Typograpy
            fontSize={isMobile ? 26 : 40}
            fontWeight="ExtraBold"
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={"#B0B8C1"}
          >
            이중화된 암호화로{" "}
          </Typograpy>
          <Typograpy
            fontSize={isMobile ? 26 : 40}
            fontWeight="ExtraBold"
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLACK}
          >
            안전하게 관리됩니다.
          </Typograpy>
        </FlexBox>
      </FlexBox>
      <motion.div
        style={{ width: "100%" }}
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={containerVariants}
      >
        <FlexBox
          $flex_direction={isMobile ? "column" : "row"}
          $isFullWidth
          $gap={2}
        >
          <motion.div
            style={{ flex: 1, width: "100%" }}
            variants={itemVariants}
          >
            <FlexBox
              $flex={1}
              $bgcolor={colors.GRAY_BG}
              $py={3}
              $ai="center"
              $br={16}
              $isFullWidth
            >
              <CloudeIc
                width={isMobile ? 6.8 : 8}
                height={isMobile ? 5.8 : 6.8}
              />
              <Box $mt={2}>
                <Typograpy
                  fontSize={18}
                  $lineHeight={21.48}
                  fontWeight="SemiBold"
                >
                  데이터 전문기업
                </Typograpy>
              </Box>
              <FlexBox $ai="center" $mt={1.5}>
                <Typograpy fontSize={14} $lineHeight={16.71}>
                  블루라이언스의 철저한 보안 관리체계로
                </Typograpy>
                <Typograpy fontSize={14} $lineHeight={16.71}>
                  고객 데이터를 안전하게 관리 합니다.
                </Typograpy>
              </FlexBox>
            </FlexBox>
          </motion.div>
          <motion.div
            style={{ width: "100%", flex: 1 }}
            variants={itemVariants}
          >
            <FlexBox
              $flex={1}
              $bgcolor={colors.GRAY_BG}
              $py={3}
              $ai="center"
              $br={16}
              $isFullWidth
            >
              <LockIc
                width={isMobile ? 4.7 : 5.2}
                height={isMobile ? 6.8 : 7.5}
              />
              <Box $mt={2}>
                <Typograpy
                  fontSize={18}
                  $lineHeight={21.48}
                  fontWeight="SemiBold"
                >
                  데이터 전문기업
                </Typograpy>
              </Box>
              <FlexBox $ai="center" $mt={1.5}>
                <Typograpy fontSize={14} $lineHeight={16.71}>
                  블루라이언스의 철저한 보안 관리체계로
                </Typograpy>
                <Typograpy fontSize={14} $lineHeight={16.71}>
                  고객 데이터를 안전하게 관리 합니다.
                </Typograpy>
              </FlexBox>
            </FlexBox>
          </motion.div>
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
